(function() {
    'use strict';

    angular.module('common')
        .run(stateInterceptor);

    stateInterceptor.$inject = ['$rootScope', '$state', 'routesConstant', 'rolesConstant', 'SessionService', 'allPermissions'];

    function stateInterceptor($rootScope, $state, routesConstant, rolesConstant, SessionService) {

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {

            if (loggedInRedirect(toState, event)) {
                return;
            }

            var stateRole = getStateRole(toState);

            if (rolesConstant[stateRole]) {
                var accessGranted = checkStateByRole(rolesConstant[stateRole]);

                if (!accessGranted) {
                    event.preventDefault();
                    $state.go(routesConstant.LOGIN.MAIN.stateName);
                }
            }

        });

        function getStateRole(toState) {
            var roles = Object.keys(rolesConstant);

            for(var i in roles) {
                for(var r in routesConstant[roles[i]]) {
                    if (routesConstant[roles[i]][r] && routesConstant[roles[i]][r].stateName
                        &&  routesConstant[roles[i]][r].stateName === toState.name) {
                        return roles[i];
                    }
                }
            }
        }

        function checkStateByRole(stateRole) {
            var appAccess = SessionService.getAppAccess();

            if (appAccess) {
                return appAccess.indexOf(stateRole) >= 0;
            }

            return false;

        }

        /**
         * If the user is logged in, redirect to the appropriate portal
         *
         * @param toState
         * @return {boolean}
         */
        function loggedInRedirect(toState, event) {
            if (toState.name === routesConstant.LOGIN.MAIN.stateName) {
                // order matters
                if (checkStateByRole(rolesConstant.CUSTOMER)) {
                    event.preventDefault();
                    $state.go(routesConstant.CUSTOMER.PROJECTS.stateName);
                    return true;
                }

                if (checkStateByRole(rolesConstant.ADMIN)) {
                    event.preventDefault();
                    $state.go(routesConstant.ADMIN.DASHBOARD.stateName, {}, { reload: true });
                    return true;
                }

                if (checkStateByRole(rolesConstant.MFG)) {
                    event.preventDefault();
                    $state.go(routesConstant.MFG.DASHBOARD.stateName);
                    return true;
                }
            }

            return false;
        }
    }
})();
